import React, { useState } from 'react';
import {
  pdf, PDFViewer, PDFDownloadLink,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import PDFListDoc from './PDFListDoc';

export default async function pdfGenerator(
  justificatories, commitmentJustificatories, justifyingImplementation, commonDocuments, commitment, warranties, allCommitmentOmegaTickets, allWarrantiesOmegaTickets,
) {
  const blobPdfReact = await pdf(<PDFListDoc
    justificatories={justificatories.map((just) => just.doc)}
    commitmentJustificatories={commitmentJustificatories}
    commonDocuments={commonDocuments}
    justifyingImplementation={justifyingImplementation}
    commitment={commitment}
    warranties={warranties}
    allCommitmentOmegaTickets={allCommitmentOmegaTickets}
    allWarrantiesOmegaTickets={allWarrantiesOmegaTickets}
  />).toBlob();

  saveAs(blobPdfReact, 'Récapitulatif_COMPLÉTUDE.pdf');
}

export function ViewPdf({
  // eslint-disable-next-line react/prop-types
  justificatories, commitmentJustificatories, justifyingImplementation, commonDocuments, commitment, warranties, allCommitmentOmegaTickets, allWarrantiesOmegaTickets,
}) {
  const [ready, setReady] = useState(false);
  setTimeout(() => {
    setReady(true);
  }, 1);
  return (
    ready && (
    <PDFViewer width="100%" height="950px">
      <PDFListDoc
        justificatories={justificatories}
        commitmentJustificatories={commitmentJustificatories}
        commonDocuments={commonDocuments}
        justifyingImplementation={justifyingImplementation}
        commitment={commitment}
        warranties={warranties}
        allCommitmentOmegaTickets={allCommitmentOmegaTickets}
        allWarrantiesOmegaTickets={allWarrantiesOmegaTickets}
      />
    </PDFViewer>
    )

  );
}

export function LinkPdf({
  // eslint-disable-next-line react/prop-types
  justificatories, commitmentJustificatories, justifyingImplementation, commonDocuments, commitment, warranties, allCommitmentOmegaTickets, allWarrantiesOmegaTickets, children, className,
}) {
  const [ready, setReady] = useState(false);
  setTimeout(() => {
    setReady(true);
  }, 1);
  return (
    ready && (
    <PDFDownloadLink
      className={className}
      document={(
        <PDFListDoc
          justificatories={justificatories}
          commitmentJustificatories={commitmentJustificatories}
          commonDocuments={commonDocuments}
          justifyingImplementation={justifyingImplementation}
          commitment={commitment}
          warranties={warranties}
          allCommitmentOmegaTickets={allCommitmentOmegaTickets}
          allWarrantiesOmegaTickets={allWarrantiesOmegaTickets}
        />
    )}
      fileName="Récapitulatif_COMPLÉTUDE.pdf"
    >
      {({
        // eslint-disable-next-line no-unused-vars
        blob, url, loading, error,
      }) => (loading ? 'Loading document...' : children)}
    </PDFDownloadLink>
    )
  );
}
