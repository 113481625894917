
export const OMEGA_TICKET_FIELDS = 'omega_ticket';
export const COMMON_FIELDS = {
  code: 'Nomenclature: ',
  commitment: 'Engagement: ',
  description: 'Description: ',
  omega_completeness: 'En savoir plus: ',
  commitment_id_code_omega_ticket: 'Code nomenclature: ',
  comment_ticket_omega: 'Commentaire: ',
  financial_condition_omega_ticket: 'Conditions financières: ',
  deadline_ticket_omega: 'Echéance: ',
  other_information_omega_ticket1: '',
  other_information_omega_ticket2: '',
  acts: 'Complétude actes: ',
};
export const NO_DOCS_NECESSARY = 'Aucun document nécessaire';
export const NO_WARRANTIES = 'Pas de garantie';

export const picturesList = new Map(
    [
  ["1", "sparkIcon"],
  ["1.1", "puzzleIcon"],
  ["1.1.1", "bnpIcon"],
  ["1.1.2", "documentIcon"],
  ["1.1.3", "puzzleIcon"],
  ["1.2", "shopIcon"],
  ["1.2.1", "shopIcon"],
  ["1.2.2", "gearsIcon"],
  ["2", "rocketIcon"],
  ["2.1", "keyIcon"],
  ["2.1.1", "parameterIcon"],
  ["2.1.2", "homeIcon"],
  ["2.2", "labelIcon"],
  ["2.2.1", "parameterIcon"],
  ["2.2.2", "homeIcon"],
  ["2.2.3", "labelIcon"],
  ["2.3", "atomIcon"],
  ["2.3.1", "shareIcon"],
  ["2.3.2", "walletIcon"],
  ["2.3.3", "atomIcon"],
  ["2.4", "walletIcon"],
  ["2.4.1", "keyIcon"],
  ["2.4.2", "walletIcon"],
  ["3", "documentIcon"],
  ["3.1", "geometryIcon"],
  ["3.1.1", "geometryIcon"],
  ["3.2", "profileIcon"],
  ["3.2.1", "profileIcon"],
  ["3.3", "documentIcon"],
  ["3.3.1", "documentIcon"],
  ["3.3.2", "documentIcon"],
  ["3.4", "documentIcon"],
  ["3.4.1", "documentIcon"],
  ["3.5", "bnpIcon"],
  ["3.5.1", "bnpIcon"],
  ]);