import React from 'react';
import PropTypes from 'prop-types';

export default function History({
  step, currentStep, value, titles, setCurrentStep, previousValue, label,
}) {
  return (
    <button
      type="button"
      className={`history${currentStep === step ? ' selected' : ''}${value.length > 0 ? ' filled' : ''}`}
      disabled={!(previousValue || value.length > 0)}
      onClick={() => { if (previousValue || value) setCurrentStep(step); }}
    >
      <div className="dot-info">
        <span className="label-number">{`0${step}`}</span>
        <span className="label">{(value && label) || value || titles[step - 1]}</span>
      </div>
      <span className="dot" />
    </button>
  );
}
History.propTypes = {
  step: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  previousValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  label: PropTypes.string,
};
History.defaultProps = {
  label: '',
  value: '',
  previousValue: '',
};
