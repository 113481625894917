import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import './index.css';
import History from './History';

export default function Breadcrump({
  customerNeed, financePurpose, commitmentType, commitment, warranties, currentStepState, titles,
}) {
  const { currentStep, setCurrentStep } = currentStepState;

  return (
    <Row className="summary">
      <Col xs={12}>
        <Row className="justify-content-center align-items-center row h-100">
          <Col xs="auto align-self-start mt-5"><h1>Récapitulatif de la demande</h1></Col>
          <Col md={12} xs={12} sm={12} className="align-self-end mb-4">
            <div className="flex-parent">
              <div className="input-flex-container">
                <History step={1} currentStep={currentStep} value={customerNeed.text} titles={titles} setCurrentStep={setCurrentStep} previousValue={customerNeed.text} />
                <History step={2} currentStep={currentStep} value={financePurpose.text} titles={titles} setCurrentStep={setCurrentStep} previousValue={customerNeed.text} />
                <History step={3} currentStep={currentStep} value={commitmentType.text} titles={titles} setCurrentStep={setCurrentStep} previousValue={financePurpose.text} />
                <History step={4} currentStep={currentStep} value={commitment.text} label={`${commitment.text} (${commitment.code})`} titles={titles} setCurrentStep={setCurrentStep} previousValue={commitmentType.text} />
                <History step={5} currentStep={currentStep} value={warranties} titles={titles} setCurrentStep={setCurrentStep} previousValue={commitment.warranties} label={(warranties.length > 0 && `${titles[4]} (${warranties.length})`) || titles[4]} />
                <History step={6} currentStep={currentStep} value={warranties} titles={titles} setCurrentStep={setCurrentStep} label={titles[5]} />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
Breadcrump.propTypes = {
  customerNeed: PropTypes.objectOf(PropTypes.any),
  financePurpose: PropTypes.objectOf(PropTypes.any),
  commitmentType: PropTypes.objectOf(PropTypes.any),
  commitment: PropTypes.objectOf(PropTypes.any),
  warranties: PropTypes.arrayOf(PropTypes.any),
  currentStepState: PropTypes.exact({
    currentStep: PropTypes.number,
    setCurrentStep: PropTypes.func,
  }).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
};
Breadcrump.defaultProps = {
  customerNeed: {
    id: '',
    text: '',
  },
  financePurpose: {
    id: '',
    text: '',
  },
  commitmentType: {
    id: '',
    text: '',
  },
  commitment: {
    commitment_id: '',
    text: '',
    code: '',
  },
  warranties: [],
};
