import React from 'react';
import {Card, Col, Row,} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {useCompletudeChoices} from '../../services/completudeChoicesService';
import {picturesList} from "../../utils/constants";

export default function CommitmentType({
  setCommitmentType, commitmentType, previousAnswerId, onNext,
}) {
  const completudeChoices = useCompletudeChoices();

  const handleCommitmentType = (id, text) => {
    if (commitmentType.id !== id) {
      setCommitmentType({id, text});
    }
    onNext();
  };

  return (
      <Row noGutters className="justify-content-center">
        <Col xs={12} lg={{span: 10, offset: 1}}>
          <Row noGutters className="justify-content-center align-items-stretch card-deck">
            {completudeChoices.getCommitmentTypes(previousAnswerId).map((v) => (
                <Col xs={12} md={4} key={v.id} className="mb-md-4 mb-3">
                  <Card key={v.id} className="card-button h-100" onClick={() => handleCommitmentType(v.id, v.text)}>
                    <Card.Body>
                      <Card.Img variant="picto"
                                src={`${process.env.PUBLIC_URL}/pictos/${picturesList.get(v.id)}.png`}/>
                      <Card.Text className="font-weight-bold m-auto">{v.text}</Card.Text>
                    </Card.Body>
                    <Card.Footer className={`green-footer${commitmentType.id === v.id ? ' selected' : ''}`}/>
                  </Card>
                </Col>
            ))}
          </Row>
        </Col>
        <Col ls={3}/>
      </Row>
  );
}
CommitmentType.propTypes = {
  setCommitmentType: PropTypes.func.isRequired,
  commitmentType: PropTypes.exact({
    id: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  previousAnswerId: PropTypes.string.isRequired,
  onNext: PropTypes.func,
};

CommitmentType.defaultProps = {
  onNext: () => {},
};
