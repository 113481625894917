/* eslint-disable react/no-array-index-key */
import React from 'react';
import {Button, Col, Container, Row,} from 'react-bootstrap';
import PropTypes from 'prop-types';

import RiskAnalyzeComponent from './riskAnalyzeComponent';
import ContractsNeedsComponent from './contractsNeedsComponent';
import OmegaTicketComponent from './omegaTicketComponent';
import './index.css';
import JustificatoryHeaderComponent from './justificatoryHeaderComponent';
import docxGenerator from './docxGenerator';
import {useCompletudeChoices} from '../../services/completudeChoicesService';
import commonDocs from '../../services/commonDocs.json';
import pdfGenerator from '../PdfGenerator/pdfGenerator';

export default function Jutificatory({
  commitment, warranties,
}) {
  const completudeChoices = useCompletudeChoices();

  const justificatories = [...new Set(warranties.flatMap((w) => (completudeChoices.getAllWarrantiesJustificatories(completudeChoices.getWarrantyCode(w)).map((d) => ({ doc: d, warranty: w })))))];
  const commitmentJustificatories = completudeChoices.getAllCommitmentJustificatories(commitment.code);
  const justifyingImplementation = completudeChoices.getJustifyingImplementation(commitment.code);
  const allCommitmentOmegaTickets = completudeChoices.getAllCommitmentOmegaTickets(commitment.code);
  const allWarrantiesOmegaTickets = warranties.flatMap((w) => completudeChoices.getAllWarrantiesOmegaTickets(completudeChoices.getWarrantyCode(w)));

  const handlePdf = () => pdfGenerator(
    justificatories,
    commitmentJustificatories,
    justifyingImplementation,
    commonDocs,
    commitment,
    warranties,
    allCommitmentOmegaTickets,
    allWarrantiesOmegaTickets,
  );
  const handleDocx = () => docxGenerator(
    justificatories,
    commitmentJustificatories,
    commonDocs,
    commitment,
    warranties,
  );

  return (
      <Container fluid className="mt-4 debriefing-container">
        <JustificatoryHeaderComponent commitment={commitment} warranties={warranties}/>
        <Row className="justify-content-center">
          <Col xs={12} md={4} className="mb-3">
            <RiskAnalyzeComponent/>
          </Col>
          <Col xs={12} md={4} className="mb-3">
            <ContractsNeedsComponent commitment={commitment} justificatories={justificatories}/>
          </Col>
          <Col xs={12} md={4} className="mb-3">
            <OmegaTicketComponent commitment={commitment} warranties={warranties}/>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <div className="d-none d-md-block">
            <Button
                className="mr-3 btn btn-bnp border-0"
                onClick={handlePdf}
            >
              Enregistrer votre récapitulatif
            </Button>
            <Button
                variant="secondary"
                onClick={handleDocx}
            >
              Générer ma proposition
            </Button>
          </div>
          <div className="d-block d-md-none">
            <Button
                className="w-100 mb-3 btn btn-bnp border-0"
            onClick={handlePdf}
          >
            Enregistrer votre récapitulatif
          </Button>
          <Button
            variant="secondary"
            className="w-100"
            onClick={handleDocx}
          >
            Générer ma proposition
          </Button>
        </div>
      </Row>
    </Container>
  );
}
Jutificatory.propTypes = {
  commitment: PropTypes.objectOf(PropTypes.any).isRequired,
  warranties: PropTypes.arrayOf(PropTypes.any).isRequired,
};
