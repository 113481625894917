import React from 'react';
import {
  Row, Card, Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useCompletudeChoices } from '../../services/completudeChoicesService';
import './index.css';
import { picturesList } from "../../utils/constants";

export default function FinancePurpose({
  setFinancePurpose, financePurpose, previousAnswerId, onNext,
}) {
  const completudeChoices = useCompletudeChoices();

  const onClickResponse = (id, text) => {
    if (financePurpose.id !== id) {
      setFinancePurpose({ id, text });
    }
    onNext();
  };

  return (
    <Row noGutters className="justify-content-center">
      <Col xs={12} lg={{ span: 10, offset: 1 }}>
        <Row noGutters className="justify-content-center align-items-stretch card-deck">
          {completudeChoices.getFinancePurpose(previousAnswerId).map((v) => (
            <Col xs={12} md={4} key={v.id} className="mb-md-4 mb-3">
              <Card key={v.id} className="card-button h-100" onClick={() => onClickResponse(v.id, v.text)}>
                <Card.Body>
                  <Card.Img variant="picto" src={`${process.env.PUBLIC_URL}/pictos/${picturesList.get(v.id)}.png`} />
                  <Card.Text className="font-weight-bold m-auto">{v.text}</Card.Text>
                </Card.Body>
                <Card.Footer className={`green-footer${financePurpose.id === v.id ? ' selected' : ''}`} />
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
      <Col />
    </Row>
  );
}

FinancePurpose.propTypes = {
  previousAnswerId: PropTypes.string.isRequired,
  setFinancePurpose: PropTypes.func.isRequired,
  financePurpose: PropTypes.exact({
    id: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  onNext: PropTypes.func,
};

FinancePurpose.defaultProps = {
  onNext: () => {},
};
