/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row,} from 'react-bootstrap';
import CardWarranty from './CardWarranty';

export default function Warranties({
  onNext, setWarranties, warranties, commitment,
}) {
  const lastWarrantyIndex = commitment.warranties.length - 1;
  const lastWarranty = commitment.warranties[lastWarrantyIndex];
  return (
      <Row noGutters className="warranties justify-content-center m-auto">
          <Col xs={12} lg={{span: 10, offset: 1}}>
              <p className="text-left text-secondary w-100 text-center">
                  Uniquement pour ce type d&apos;engagement, voici les garanties utilisées. D&apos;autres garanties plus
                  appropriées peuvent être choisies. Vérifiez que les garanties choisies soient cohérentes vis-à-vis de
                  votre
                  objet de financement.
              </p>
              <Row noGutters className="justify-content-center align-items-stretch card-deck">
                  {commitment.warranties.map((warranty, i) => {
                      const disabled = warranties.length > 0 && lastWarrantyIndex === i
                          ? !(lastWarranty === warranties[0])
                          : lastWarranty === warranties[0];
                      return (
                          <CardWarranty
                              key={i}
                              warranty={warranty}
                              index={i}
                              setWarranties={setWarranties}
                              warranties={warranties}
                              selectedDefault={warranties.filter((d) => d === warranty).length > 0}
                              disabled={disabled}
                          />
                      );
                  })}
              </Row>
          </Col>
          <Col/>
          <Col xs={12}>
              <Button variant="bnp" disabled={warranties.length === 0} onClick={() => onNext()}>Valider la
                  demande</Button>
          </Col>
      </Row>
  );
}
Warranties.propTypes = {
  onNext: PropTypes.func,
  setWarranties: PropTypes.func.isRequired,
  warranties: PropTypes.arrayOf(PropTypes.any).isRequired,
  commitment: PropTypes.exact({
    commitment_id: PropTypes.string,
    code: PropTypes.string,
    text: PropTypes.string,
    warranties: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
Warranties.defaultProps = {
  onNext: () => {},
};
