import React, { useState, useRef } from 'react';
import {
  Col, Card, Form, Tooltip, OverlayTrigger, Image,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import './index.css';
import infoIcon from '../../../pictos/info.png';
import { useCompletudeChoices } from '../../../services/completudeChoicesService';

export default function CardWarranty({
  warranty, index, selectedDefault, setWarranties, warranties, disabled,
}) {
  const [selected, setSelected] = useState(selectedDefault);
  const completudeChoices = useCompletudeChoices();
  const infoRef = useRef();
  const description = completudeChoices.getWarrantyDescription(warranty);

  const handleWarranty = () => {
    if (!disabled) {
      const warrantiesFiltered = warranties.filter((d) => d !== warranty);
      if (!selected) {
        setWarranties([...warrantiesFiltered, warranty]);
      } else {
        setWarranties(warrantiesFiltered);
      }
      setSelected(!selected);
    }
  };

  return (
    <Col xs={12} md={3} className="mb-md-4 mb-3">
      <Card
        className={`card-button h-100${disabled ? ' disabled' : ''}`}
        onClick={(e) => {
          if (infoRef.current && e.target.toString() === infoRef.current.toString()) {
            e.preventDefault();
          } else {
            handleWarranty();
          }
        }}
      >
        <Card.Body className="d-flex justify-content-center align-items-center">
          {description && (
          <OverlayTrigger overlay={<Tooltip>{description}</Tooltip>}>
            <Image ref={infoRef} className="info" src={infoIcon} alt="info" />
          </OverlayTrigger>
          )}
          <Form.Check
            className="check-box"
            custom
            type="checkbox"
            id={index}
            label=""
            checked={selected || selectedDefault}
            readOnly
            disabled={disabled}
          />
          <Card.Text>{warranty}</Card.Text>
        </Card.Body>
        <Card.Footer className={`green-footer${(selected || selectedDefault) ? ' selected' : ''}`} />
      </Card>
    </Col>
  );
}

CardWarranty.propTypes = {
  warranty: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  setWarranties: PropTypes.func.isRequired,
  selectedDefault: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  warranties: PropTypes.arrayOf(PropTypes.any).isRequired,
};
