import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  OMEGA_TICKET_FIELDS, COMMON_FIELDS, NO_WARRANTIES,
} from '../utils/constants';

const CompletudeChoicesContext = createContext();

function getOneJsonDocLine(jsonDocument, code) {
  return jsonDocument.find((com) => com.code === code);
}

function getAllConcernedKeys(lineOfJson) {
  const keys = Object.keys(lineOfJson);
  const allKeys = keys.filter((k) => k.includes(OMEGA_TICKET_FIELDS));
  return allKeys;
}

function getAllConcernedFields(jsonDocument, code) {
  if (!code) {
    return [];
  }
  const lineOfJson = getOneJsonDocLine(jsonDocument, code);
  const keys = getAllConcernedKeys(lineOfJson);
  const fields = [];
  for (let i = 0; i < keys.length; i++) {
    fields.push({ fieldName: COMMON_FIELDS[keys[i]], value: lineOfJson[keys[i]] });
  }

  return fields;
}

export const CompletudeChoicesProvider = (props) => {
  const {
    children, tree, commitments, warranties,
  } = props;

  function getWarrantyCode(warranty) {
    if (warranties.find((w) => w.warranty === warranty)) {
      return warranties.find((w) => w.warranty === warranty).code;
    }

    return null;
  }

  function getWarrantyDescription(warranty) {
    const code = getWarrantyCode(warranty);
    if (code) {
      return warranties.find((w) => w.code === code).description;
    }
    return null;
  }

  function getAllWarrantiesJustificatories(code) {
    const warranty = warranties.find((com) => com.code === code);
    if (!warranty) {
      return [];
    }

    return warranty.docs;
  }

  function isOneOrMoreWarranties(warranties) {
    if (warranties.length === 1 && warranties[0] === NO_WARRANTIES) {
      return false;
    }

    return true;
  }

  const value = {
    tree,
    getCustomerNeed: () => tree.customer_need.map((v) => ({ id: v.id.toString(), text: v.text })),
    findCustomerNeed: (id) => tree.customer_need.find((d) => id.split('.')[0] === d.id.toString()),
    getCommitmentTypes: (previousId) => tree.commitment_type.filter((d) => RegExp(`^${previousId}`).test(d.id)),
    getCommitments: (previousId) => tree.commitment.filter((d) => RegExp(`^${previousId}`).test(d.commitment_id)),
    getAllCommitments: () => tree.commitment,
    findOneCommitmentType: (id) => tree.commitment_type.find((d) => d.id === id),
    findOneCommitment: (code) => tree.commitment.find((d) => d.code === code),
    getFinancePurpose: (previousId) => tree.finance_purpose.filter((d) => RegExp(`^${previousId}`).test(d.id)),
    findFinancePurpose: (id) => tree.finance_purpose.find((d) => RegExp(`^${d.id}`).test(id)),
    getJustifyingImplementation: (code) => commitments.find((com) => com.code === code).justifying_implementation,
    getAllCommitmentJustificatories: (code) => commitments.find((com) => com.code === code).docs,
    getAllCommitmentOmegaTickets: (code) => getAllConcernedFields(commitments, code),
    getWarrantyCode: (warranty) => getWarrantyCode(warranty),
    getAllWarrantiesJustificatories: (code) => getAllWarrantiesJustificatories(code),
    getAllWarrantiesOmegaTickets: (code) => getAllConcernedFields(warranties, code),
    getCommitmentOmegaCompleteness: (code) => commitments.find((com) => com.code === code).omega_completeness,
    getCommitmentDescription: (code) => commitments.find((com) => com.code === code).description,
    getWarrantyDescription,
    getCommitmentCompletenessActs: (code) => commitments.find((com) => com.code === code).acts,
    isOneOrMoreWarranties,
  };

  return (
    <CompletudeChoicesContext.Provider value={value}>
      {children}
    </CompletudeChoicesContext.Provider>
  );
};

export const useCompletudeChoices = () => useContext(CompletudeChoicesContext);

CompletudeChoicesProvider.propTypes = {
  tree: PropTypes.objectOf(PropTypes.any).isRequired,
  commitments: PropTypes.arrayOf(PropTypes.any).isRequired,
  warranties: PropTypes.arrayOf(PropTypes.any).isRequired,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
};
