import React, { useRef } from 'react';
import {
  Row, Col, Card, OverlayTrigger, Tooltip, Image,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useCompletudeChoices } from '../../services/completudeChoicesService';
import infoIcon from '../../pictos/info.png';
import './index.css';

export default function Commitment({
  setCommitment, commitment, previousAnswerId, onNext, setWarranties,
}) {
  const completudeChoices = useCompletudeChoices();
  const infoRef = useRef();

  const handleCommitment = (code) => {
    if (commitment.code !== code) {
      setCommitment(completudeChoices.findOneCommitment(code));
      setWarranties([]);
    }
    onNext(5);
  };

  return (
    <Row noGutters className="commitments justify-content-center">
      <Col xs={12} lg={{ span: 10, offset: 1 }}>
        <Row noGutters className="justify-content-center align-items-stretch card-deck">
          {completudeChoices.getCommitments(previousAnswerId).map((v) => (
            <Col xs={12} md={4} key={v.code} className="mb-md-4 mb-3">
              <Card
                key={v.id}
                className="card-button h-100"
                onClick={(e) => {
                  if (e.target.toString() === infoRef.current.toString()) {
                    e.preventDefault();
                  } else {
                    handleCommitment(v.code);
                  }
                }}
              >
                <Card.Body className="d-flex flex-column">
                  <OverlayTrigger overlay={<Tooltip>{completudeChoices.getCommitmentDescription(v.code)}</Tooltip>}>
                    <Image ref={infoRef} className="info" src={infoIcon} alt="info" />
                  </OverlayTrigger>
                  <Card.Title className="font-weight-bold m-auto title">{v.text}</Card.Title>
                  <Card.Text className="font-weight-bold m-auto code"><b>{v.code}</b></Card.Text>
                </Card.Body>
                <Card.Footer className={`green-footer${commitment.code === v.code ? ' selected' : ''}`} />
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
      <Col ls={3} />
    </Row>
  );
}

Commitment.propTypes = {
  setCommitment: PropTypes.func.isRequired,
  setWarranties: PropTypes.func.isRequired,
  commitment: PropTypes.objectOf(PropTypes.any).isRequired,
  previousAnswerId: PropTypes.string,
  onNext: PropTypes.func,
};

Commitment.defaultProps = {
  onNext: () => { },
  previousAnswerId: '',
};
