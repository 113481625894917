/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Document, Text, View, StyleSheet, Image, Page,
} from '@react-pdf/renderer';
import BNPP_BL_Q from './BNPP_BL_Q.png';

const DOC_CLIENT_IDENTIFIER = /^\*/;

const styles = StyleSheet.create({
  page: {
    fontSize: 9,
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 30,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    alignContent: 'flex-start',
    marginBottom: 10,
  },
  card: {
    padding: 10,
    width: '49%',
    backgroundColor: '#ececec',
    borderRadius: 5,
  },
  cardWhite: {
    width: '49%',
    border: 1,
    borderStyle: 'dotted',
    borderColor: '#e4e4e4',
    marginBottom: 10,
  },
  details: {
    fontSize: 8,
    color: 'grey',
  },
  text: {
    paddingBottom: 5,
    marginLeft: 10,
  },
  title: {
    paddingBottom: 10,
    fontSize: 12,
    color: '#00915A',
  },
  dot: {
    color: '#00915A',
  },
  h2: {
    marginLeft: 10,
    paddingBottom: 5,
    fontSize: 11,
    color: '#00915A',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  logo: {
    position: 'absolute',
    left: 28,
    top: 20,
    width: '100px',
    height: '28px',
  },
});

export default function PDFlistDoc({
  justificatories, commitmentJustificatories, justifyingImplementation, commonDocuments, commitment, warranties, allCommitmentOmegaTickets, allWarrantiesOmegaTickets,
}) {
  return (
    <Document>
      <Page style={styles.page} size="A4" wrap>
        <Image fixed style={styles.logo} src={BNPP_BL_Q} />
        <Text style={styles.header} fixed>
          COMPLÉTUDE DE VOTRE DEMANDE
        </Text>

        <View style={styles.section}>
          <View style={styles.card}>
            <Text style={styles.title}>Votre engagement</Text>
            <Text style={styles.text}>
              <Text style={styles.dot}>•</Text> {commitment.text} ({commitment.code})
            </Text>
          </View>

          <View style={styles.card}>
            <Text style={styles.title}>Vos garanties</Text>
            {warranties.map((warranty, i) => (
              <Text key={i} style={styles.text}>
                <Text style={styles.dot}>•</Text> {warranty}
              </Text>
            ))}
          </View>
        </View>

        <View style={styles.section}>
          <View style={{ ...styles.cardWhite, width: '100%' }}>
            <Text style={styles.title}>Documents pour la rédaction des contrats</Text>
            <Text style={styles.h2}>Pour l&apos;engagement</Text>
            {commitmentJustificatories.map((commitmentJustificatory, i) => (
              <View key={i} style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ ...styles.text, width: '503px' }}><Text style={styles.dot}>•</Text> {commitmentJustificatory.replace(DOC_CLIENT_IDENTIFIER, '')}</Text>
              </View>
            ))}

            {justificatories.length > 0 && (
              <>
                <Text style={styles.h2}>Pour les garanties</Text>
                {justificatories.map((justificatory, i) => (
                  <View key={i} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ ...styles.text, width: '503px' }}><Text style={styles.dot}>•</Text> {justificatory.replace(DOC_CLIENT_IDENTIFIER, '')}</Text>
                  </View>
                ))}
              </>
            )}
          </View>

          <View style={styles.cardWhite}>
            <Text style={styles.title}>Documents pour l&apos;analyse risque et la décision</Text>
            {commonDocuments.basis.map((d, i) => (
              <View key={i} style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ ...styles.text, width: '233px' }}><Text style={styles.dot}>•</Text> {d.name.replace(DOC_CLIENT_IDENTIFIER, '')}</Text>
              </View>
            ))}
          </View>

          <View style={styles.cardWhite}>
            <Text style={styles.title}>Informations importantes de complétude du ticket Omega</Text>
            <Text style={styles.h2}>Pour l&apos;engagement</Text>
            {allCommitmentOmegaTickets.map((just, i) => (
              <Text key={i} style={styles.text}>
                <Text style={styles.dot}>•</Text> {just.fieldName} : {just.value}
              </Text>
            ))}
            {allWarrantiesOmegaTickets.length > 0 && (
              <>
                <Text style={styles.h2}>Pour les garanties</Text>
                {allWarrantiesOmegaTickets.map((just, i) => (
                  <Text key={i} style={styles.text}>
                    <Text style={styles.dot}>•</Text> {just.fieldName} : {just.value}
                  </Text>
                ))}
              </>
            )}
          </View>

          <View style={{ ...styles.cardWhite, width: '100%' }}>
            <Text style={styles.title}>Documents pour la mise en oeuvre</Text>
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ ...styles.text, width: '503px' }}><Text style={styles.dot}>•</Text> Si passage en Agence Crédit, copie de l'écran de synthèse ILC (dernier écran du parcours)</Text>
              {justifyingImplementation!=='' && (
                  <Text style={{ ...styles.text, width: '503px' }}><Text style={styles.dot}>•</Text> {justifyingImplementation.replace(DOC_CLIENT_IDENTIFIER, '')}</Text>
              )}
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )}
          fixed
        />
      </Page>
    </Document>
  );
}

PDFlistDoc.propTypes = {
  justificatories: PropTypes.arrayOf(PropTypes.any).isRequired,
  commitmentJustificatories: PropTypes.arrayOf(PropTypes.any).isRequired,
  commonDocuments: PropTypes.objectOf(PropTypes.any).isRequired,
  justifyingImplementation: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  commitment: PropTypes.objectOf(PropTypes.any).isRequired,
  warranties: PropTypes.arrayOf(PropTypes.string).isRequired,
  allCommitmentOmegaTickets: PropTypes.arrayOf(PropTypes.any),
  allWarrantiesOmegaTickets: PropTypes.arrayOf(PropTypes.any),
};
PDFlistDoc.defaultProps = {
  justifyingImplementation: false,
  allCommitmentOmegaTickets: [],
  allWarrantiesOmegaTickets: [],
};
