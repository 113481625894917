/* eslint-disable react/no-array-index-key */
import React from 'react';
import {Card, ListGroup,} from 'react-bootstrap';
import PropTypes from 'prop-types';

function getWarranties(allJustificatories) {
  return [...new Set(allJustificatories.map((just) => just.warranty))];
}

function getAllJustificatoriesForAWarranty(allJustificatories, warranty) {
    return [...new Set(allJustificatories.filter((just) => just.warranty === warranty).map((j) => j.doc))];
}

function isWarrantyArrayOfJson(allJustificatories) {
    return allJustificatories.length > 0 && allJustificatories[0].doc;
}

function renderLine(line) {
    const splitDoc = line.split('[lien-doc]');
    const splitLink = line.split('[lien]');
    let showedLine = line;
    if (splitLink.length === 2) {
        showedLine = (
            <>
                {splitLink[0]}
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={splitLink[1]} className="ml-1 file-link font-weight-bold" target="_blank">
                    (Lien vers la page)
                </a>
            </>
        );
    }
    if (splitDoc.length === 2) {
        showedLine = (
            <>
                {splitDoc[0]}
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={splitDoc[1]} className="ml-1 file-link font-weight-bold" target="_blank">
                    (Lien vers le document)
                </a>
            </>
        );
    }
    return showedLine;
}

export default function ListGroupJustificatories({title, allJustificatories}) {
    return (
        <Card className="border-0">
            <Card.Body className="px-0">
                <Card.Title className="text-success">{title}</Card.Title>
                {isWarrantyArrayOfJson(allJustificatories) && getWarranties(allJustificatories).map((warranty, index) => (
                    <ListGroup key={index}>
                        Les documents à fournir pour {warranty}: <br/>
                        {getAllJustificatoriesForAWarranty(allJustificatories, warranty).map((justificatory, index) => (
                            <ListGroup.Item key={index} className="border-0 text-left">
                                <div className="bg-dark d-inline-block rounded-circle mb-1 mr-1"
                                     style={{width: '5px', height: '5px'}}/>
                                {renderLine(justificatory.replace(/^\*/, ''))}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                ))}
                {!isWarrantyArrayOfJson(allJustificatories)
                    && (
                        <ListGroup>
                            {allJustificatories.length === 0 && <div>Pas de garantie</div>}
                            {allJustificatories.length > 0 && allJustificatories.map((just, index) => (
                                <ListGroup.Item key={index}
                                                className={`border-0 text-left ${just[0] === '*' ? 'ml-4' : ''}`}>
                                    <div className="bg-dark d-inline-block rounded-circle mb-1 mr-1"
                                         style={{width: '5px', height: '5px'}}/>
                                    {just && !just.doc && renderLine(just.replace(/^\*/, ''))}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    )}
      </Card.Body>
    </Card>
  );
}
ListGroupJustificatories.propTypes = {
  title: PropTypes.string.isRequired,
  allJustificatories: PropTypes.arrayOf(PropTypes.any).isRequired,
};
