import React from 'react';
import {
  Card,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ListGroupJustificatories from './justificatory.utils';
import { useCompletudeChoices } from '../../services/completudeChoicesService';

export default function ContractsNeeds({ justificatories, commitment }) {
  const completudeChoices = useCompletudeChoices();

  return (
    <div>
      <Card className="shadow">
        <Card.Body>
          <Card.Title>
            <div>
              <span className="text-success font-weight-bold">Documents pour la rédaction des contrats</span>
            </div>
          </Card.Title>
          <ListGroupJustificatories title="Pour l&apos;engagement" allJustificatories={completudeChoices.getAllCommitmentJustificatories(commitment.code)} />
          <ListGroupJustificatories title="Pour les garanties" allJustificatories={justificatories} />
          <div className="border">
            <Card.Link className={`float-left mt-3 text-dark ${completudeChoices.getCommitmentCompletenessActs(commitment.code) !== undefined ? '' : 'disabled-link'}`} target="_blank" href={completudeChoices.getCommitmentCompletenessActs(commitment.code)}>Voir la fiche produit ></Card.Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
ContractsNeeds.propTypes = {
  commitment: PropTypes.objectOf(PropTypes.any).isRequired,
  justificatories: PropTypes.arrayOf(PropTypes.any).isRequired,
};
