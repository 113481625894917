import React from 'react';
import {
  Row, Card, Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useCompletudeChoices } from '../../services/completudeChoicesService';
import CommitmentSearch from '../CommitmentSearch';
import {picturesList} from "../../utils/constants";

export default function CustomerNeed({
  setCustomerNeed, customerNeed, onNext, setCommitment, commitment, setWarranties,
}) {
  const completudeChoices = useCompletudeChoices();

  const onClickResponse = (id, text) => {
    if (customerNeed.id.toString() !== id) {
      setCustomerNeed({ id, text });
    }
    onNext();
  };

  return (
    <>
      <Row noGutters className="justify-content-center mb-5">
        <Col xs={12} lg={{ span: 10, offset: 1 }}>
          <Row noGutters className="justify-content-center align-items-stretch card-deck">
            {completudeChoices.getCustomerNeed().map((v) => (
              <Col xs={12} md={4} key={v.id} className="mb-md-4 mb-3">
                <Card key={v.id} className="card-button h-100" onClick={() => onClickResponse(v.id, v.text)}>
                  <Card.Body>
                    <Card.Img variant="picto" src={`${process.env.PUBLIC_URL}/pictos/${picturesList.get(v.id)}.png`} />
                    <Card.Text className="font-weight-bold">{v.text}</Card.Text>
                  </Card.Body>
                  <Card.Footer className={`green-footer${customerNeed.id.toString() === v.id ? ' selected' : ''}`} />
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
        <Col />
      </Row>
      <Col xs={12}><h3>Si vous connaissez dejà votre engagement :</h3></Col>
      <CommitmentSearch
        onNext={onNext}
        setCommitment={setCommitment}
        commitment={commitment}
        setWarranties={setWarranties}
      />
    </>
  );
}

CustomerNeed.propTypes = {
  setCustomerNeed: PropTypes.func.isRequired,
  customerNeed: PropTypes.exact({
    id: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  onNext: PropTypes.func,
  setCommitment: PropTypes.func.isRequired,
  setWarranties: PropTypes.func.isRequired,
  commitment: PropTypes.exact({
    commitment_id: PropTypes.string,
    code: PropTypes.string,
    text: PropTypes.string,
    warranties: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

CustomerNeed.defaultProps = {
  onNext: () => {},
};
