/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './App.css';
import TemplateForm from './components/TemplateForm';
import Breadcrumb from './components/Breadcrump';
import { useCompletudeChoices } from './services/completudeChoicesService';

const defaultChoice = { id: '', text: '' };
const defaultChoiceCommitment = { commitment_id: '', text: '', code: '' };
const titles = [
  'Sélectionnez votre besoin',
  'Sélectionnez l\'objet de votre financement',
  'Sélectionnez votre type d\'engagement',
  'Sélectionnez votre engagement',
  'Sélectionnez vos garanties',
  'Complétude de votre demande',
];

function App() {
  const completudeChoices = useCompletudeChoices();
  const [customerNeed, setCustomerNeed] = useState(defaultChoice);
  const [commitmentType, setCommitmentType] = useState(defaultChoice);
  const [financePurpose, setFinancePurpose] = useState(defaultChoice);
  const [commitment, setCommitment] = useState(defaultChoiceCommitment);
  const [warranties, setWarranties] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [autoFill, setAutoFill] = useState(false);

  useEffect(() => {
    if (!autoFill) setFinancePurpose(defaultChoice);
  }, [customerNeed]);
  useEffect(() => {
    if (!autoFill) setCommitmentType(defaultChoice);
  }, [financePurpose]);
  useEffect(() => {
    if (!autoFill) setCommitment(defaultChoiceCommitment);
  }, [commitmentType]);
  useEffect(() => {
    if (!autoFill) setWarranties([]);
  }, [commitment]);

  useEffect(() => {
    if (!autoFill && currentStep === 5) {
      setAutoFill(true);
      setCustomerNeed(completudeChoices.findCustomerNeed(commitment.commitment_id));
      setFinancePurpose(completudeChoices.findFinancePurpose(commitment.commitment_id));
      setCommitmentType(completudeChoices.findOneCommitmentType(commitment.commitment_id));
    } else if (autoFill) {
      setAutoFill(false);
    }
  }, [commitment, currentStep]);

  return (
    <Container fluid>
      <TemplateForm
        currentStepState={{ currentStep, setCurrentStep }}
        customerNeedState={{ customerNeed, setCustomerNeed }}
        financePurposeState={{ financePurpose, setFinancePurpose }}
        commitmentTypeState={{ commitmentType, setCommitmentType }}
        commitmentState={{ commitment, setCommitment }}
        warrantiesState={{ warranties, setWarranties }}
        titles={titles}
      />
      <Breadcrumb
        totalStep={titles.length}
        currentStepState={{ currentStep, setCurrentStep }}
        customerNeed={customerNeed}
        commitmentType={commitmentType}
        financePurpose={financePurpose}
        commitment={commitment}
        warranties={warranties}
        titles={titles}
      />
    </Container>
  );
}

export default App;
