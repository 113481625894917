import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './index.css';

const BoldString = ({ str, find }) => {
  const re = new RegExp(`(${find.join('|')}+)`, 'gi');
  const parts = str.split(re);
  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = <b key={i}>{parts[i]}</b>;
  }
  return <div>{parts}</div>;
};
BoldString.propTypes = {
  str: PropTypes.string.isRequired,
  find: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function HeaderBnpForm({ totalStep, currentStep, titles }) {
  return (
    <Row className="justify-content-center header-bnp-form">
      <Col xs="auto mb-3">
        <h2>{`Étape ${currentStep}/${totalStep}`}</h2>
        <h1><BoldString str={titles[currentStep - 1]} find={['garanties', 'type d\'engagement', '(?:d\')?engagement', 'financement', 'besoin', 'demande']} /></h1>
      </Col>
    </Row>
  );
}
HeaderBnpForm.propTypes = {
  totalStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  titles: PropTypes.arrayOf(PropTypes.string),
};
HeaderBnpForm.defaultProps = {
  titles: [],
};
