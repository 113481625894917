import React from 'react';
import {
  ListGroup, Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import commonDocs from '../../services/commonDocs.json';

export default function RiskAnalyzeComponent() {
  return (
    <Card className="shadow">
      <Card.Body>
        <Card.Title>
          <div>
            <span className="text-success font-weight-bold">Documents pour l&apos;analyse risque et la décision</span>
          </div>
        </Card.Title>
        <Card.Text className="text-muted font-italic text-xs-17">
        Liste non exhaustive, ne se substitue pas à l’enrichissement de la Note de Synthèse. Se référer à la RSQ-II-016-003.
        En fonction de la qualité de l’emprunteur, de l’objet du financement et des garanties proposées, certains documents pour la rédaction des actes peuvent être nécessaires dès l’analyse.
        </Card.Text>
        <ListGroup className="d-flex justify-content-start">
          {commonDocs.basis.map((doc) => (
            <ListGroup.Item key={doc.name} className="border-0 text-left">
              <div className="bg-dark d-inline-block rounded-circle mb-1 mr-1" style={{ width: '5px', height: '5px' }} />
              {doc.name.replace(/^\*/, '')}
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Link className="btn btn-info" to="/ComplementaryComponent" target="_blank">Liste indicative complémentaire &gt;</Link>
      </Card.Body>
    </Card>
  );
}
