import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './fonts/bnpp-sans/bnpp-sans.ttf';
import { CompletudeChoicesProvider } from './services/completudeChoicesService';
import tree from './services/tree.json';
import commitments from './services/commitments.json';
import warranties from './services/warranties.json';
import ComplementaryComponent from './components/Justificatory/complementaryDocsComponent';


ReactDOM.render(
  <HashRouter basename="/">
    <Switch>
      <Route path="/" exact>
        <CompletudeChoicesProvider tree={tree} commitments={commitments} warranties={warranties}>
          <App />
        </CompletudeChoicesProvider>
      </Route>
      <Route path="/ComplementaryComponent" component={ComplementaryComponent} />
    </Switch>
  </HashRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
