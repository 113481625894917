import React from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';

import './index.css';

import Header from '../Header';
import CustomerNeed from '../CustomerNeed';
import CommitmentType from '../CommitmentType';
import NavButtons from '../NavButtons';
import FinancePurpose from '../FinancePurpose';
import JustificatoryPage from '../Justificatory';
import Commitment from '../Commitment';
import Warranties from '../Warranties';

export default function TemplateForm({
  customerNeedState, financePurposeState, commitmentTypeState, commitmentState, warrantiesState, currentStepState, titles,
}) {
  const { currentStep, setCurrentStep } = currentStepState;

  const { customerNeed, setCustomerNeed } = customerNeedState;
  const { commitmentType, setCommitmentType } = commitmentTypeState;
  const { financePurpose, setFinancePurpose } = financePurposeState;
  const { commitment, setCommitment } = commitmentState;
  const { warranties, setWarranties } = warrantiesState;

  const onNext = (step) => {
    if (step) {
      setCurrentStep(step);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onBack = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Row className={`template-bnp-form ${currentStep === 6 ? 'background-color-white' : ''}`}>
      <Col xs={6} className={`${currentStep === 6 ? 'background-color-white' : ''}`}>
        <NavButtons currentStep={currentStep} totalStep={titles.length} onBack={onBack} />
      </Col>
      <Col xs={6}>
          {/* TODO changer l'adresse email par celle qui sera donnée */}
        <button className="btn btn-info float-right" type="button" onClick={() => window.open('mailto:BCEF.CORPORATECOMPLETUDE@bnpparibas.com')}>Nous contacter</button>
      </Col>
      <Col xs={12} className="mb-4">
        <Header totalStep={titles.length} currentStep={currentStep} titles={titles} />
      </Col>
      <Col xs={12} className="mb-2">
        {currentStep === 1 && (
        <CustomerNeed
          onNext={onNext}
          setCustomerNeed={setCustomerNeed}
          customerNeed={customerNeed}
          setCommitment={setCommitment}
          commitment={commitment}
          setWarranties={setWarranties}
        />
        )}
        {currentStep === 2 && (
        <FinancePurpose
          onNext={onNext}
          setFinancePurpose={setFinancePurpose}
          financePurpose={financePurpose}
          previousAnswerId={customerNeed.id.toString()}
        />
        )}
        {currentStep === 3 && (
        <CommitmentType
          onNext={onNext}
          setCommitmentType={setCommitmentType}
          commitmentType={commitmentType}
          previousAnswerId={financePurpose.id}
        />
        )}
        {currentStep === 4 && (
        <Commitment
          onNext={onNext}
          setCommitment={setCommitment}
          commitment={commitment}
          previousAnswerId={commitmentType.id}
          warranties={warranties}
          setWarranties={setWarranties}
        />
        )}
        {currentStep === 5 && (
        <Warranties
          onNext={onNext}
          setWarranties={setWarranties}
          warranties={warranties}
          commitment={commitment}
        />
        )}
        {currentStep === 6 && (
        <JustificatoryPage
          commitment={commitment}
          warranties={warranties}
        />
        )}
      </Col>
    </Row>
  );
}
TemplateForm.propTypes = {
  customerNeedState: PropTypes.objectOf(PropTypes.any).isRequired,
  financePurposeState: PropTypes.objectOf(PropTypes.any).isRequired,
  commitmentTypeState: PropTypes.objectOf(PropTypes.any).isRequired,
  commitmentState: PropTypes.objectOf(PropTypes.any).isRequired,
  warrantiesState: PropTypes.objectOf(PropTypes.any).isRequired,
  currentStepState: PropTypes.objectOf(PropTypes.any).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
};
