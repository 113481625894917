import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Button, InputGroup,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useCompletudeChoices } from '../../services/completudeChoicesService';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import icon from '../../pictos/valid.png';

import './index.css';

export default function CommitmentSearch({
  setCommitment, commitment, onNext, setWarranties,
}) {
  const completudeChoices = useCompletudeChoices();
  const typeahead = useRef();
  const [commitmentSelected, setCommitmentSelected] = useState([]);

  const handleCommitment = (e) => {
    e.preventDefault();
    const selectedCode = commitmentSelected.pop().code;
    typeahead.current.getInstance().clear();
    if (selectedCode) {
      if (commitment.code !== completudeChoices.findOneCommitment(selectedCode).code) {
        setCommitment(completudeChoices.findOneCommitment(selectedCode));
        setWarranties([]);
      }
      onNext(5);
    }
  };

  return (
    <Row className="justify-content-center commitment-search mb-5">
      <Col xs={12} lg={{ span: 6, offset: 3 }}>
        <form onSubmit={handleCommitment}>
          <InputGroup>
            <Typeahead
              ref={typeahead}
              bsSize="large"
              id="commitment-search"
              onChange={(selected) => {
                setCommitmentSelected(selected);
              }}
              selected={commitmentSelected}
              placeholder="Rechercher un engagement (nom, code, ...)"
              labelKey={(option) => `${option.text} (${option.code})`}
              options={completudeChoices.getAllCommitments()}
              filterBy={['text', 'code']}
              selectHintOnEnter
              flip
              highlightOnlyResult
            />
            <InputGroup.Append>
              <Button type="submit" variant="bnp" disabled={!commitmentSelected.length > 0}>
                <img src={icon} alt="" style={{ maxHeight: '24px' }} />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </form>
      </Col>
      <Col ls={3} />
    </Row>
  );
}

CommitmentSearch.propTypes = {
  setCommitment: PropTypes.func.isRequired,
  setWarranties: PropTypes.func.isRequired,
  commitment: PropTypes.exact({
    commitment_id: PropTypes.string,
    code: PropTypes.string,
    text: PropTypes.string,
    warranties: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onNext: PropTypes.func,
};

CommitmentSearch.defaultProps = {
  onNext: () => {},
};
