import {
  saveAs,
} from 'file-saver';
import createDocx from 'docx-templates';
import docTemplate from './Proposition_Commerciale_Standard_DRAFT.docx';

const DOC_CLIENT_IDENTIFIER = /^\*/;

export default async function docxGenerator(justificatories, commitmentJustificatories, commonDocuments, commitment, warranties) {
  const getJustificatoriesFiltered = justificatories.filter((str) => DOC_CLIENT_IDENTIFIER.test(str.doc));
  const getBasisCommonDocumentsFiltered = commonDocuments.basis.filter((d) => DOC_CLIENT_IDENTIFIER.test(d.name));
  const getComplementaryCommonDocumentsFiltered = commonDocuments.complementary.filter((d) => DOC_CLIENT_IDENTIFIER.test(d.name));
  const getCommitmentJustificatoriesFiltered = commitmentJustificatories.filter((str) => DOC_CLIENT_IDENTIFIER.test(str.doc));

  const fileTemplate = await fetch(docTemplate);
  const template = await fileTemplate.arrayBuffer();
  const report = await createDocx({
    cmdDelimiter: ['{', '}'],
    template,
    data: {
      commitmentType: commitment.text,
      warranties,
      justificatories: getJustificatoriesFiltered.map((str) => str.doc.replace(DOC_CLIENT_IDENTIFIER, '')),
      commitmentJustificatories: getCommitmentJustificatoriesFiltered.map((str) => str.doc.replace(DOC_CLIENT_IDENTIFIER, '')),
      basisCommonDocuments: getBasisCommonDocumentsFiltered.map((d) => ({
        name: d.name.replace(DOC_CLIENT_IDENTIFIER, ''),
      })),
      complementaryCommonDocuments: getComplementaryCommonDocumentsFiltered.map((d) => ({
        complementary: d.complementary.replace(DOC_CLIENT_IDENTIFIER, ''),
      })),
    },
    noSandbox: true,
  });
  const blob = new Blob([report], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });
  saveAs(blob, 'Proposition Commerciale Standard.docx');
}
