import React from 'react';
import {
  Table, Container,
} from 'react-bootstrap';
import commonDocs from '../../services/commonDocs.json';

export default function ComplementaryComponent() {
  return (
    <Container fluid>
      <Table>
        <thead key="thead">
          <tr>
            <th>Condition spécifique</th>
            <th>Document </th>
          </tr>
        </thead>
        <tbody>
          {commonDocs.complementary.map((doc, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={index}>
              <td className="text-left">{doc.details}</td>
              <td className="text-left">{doc.name.replace(/^\*/, '')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
