import React from 'react';
import {
  Col, Row, ListGroup, Card, Image,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function JustificatoryHeaderComponent({ commitment, warranties }) {
  return (
    <Row className="mb-3">
      <Col xs={12} md={6} className="mb-2">
        <Card>
          <Card.Body className="card-background-color">
            <Card.Title className="d-flex justify-content-start">Votre engagement</Card.Title>
            <div className="d-flex justify-content-start">
              <Image width={10} height={10} src={`${process.env.PUBLIC_URL}/diamond.png`} className="d-flex align-self-center mr-1" />
              <div className="text-left">{commitment.text}</div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={6}>
        <Card>
          <Card.Body className="card-background-color">
            <Card.Title className="d-flex justify-content-start">Vos garanties</Card.Title>
            <ListGroup>{warranties.map((w) => (
              <ListGroup.Item key={w} className="border-0 p-0 d-flex justify-content-start card-background-color text-left">
                <Image width={10} height={10} src={`${process.env.PUBLIC_URL}/diamond.png`} className="d-flex align-self-center mr-1" />
                {w}
              </ListGroup.Item>
            ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
JustificatoryHeaderComponent.propTypes = {
  commitment: PropTypes.objectOf(PropTypes.any).isRequired,
  warranties: PropTypes.arrayOf(PropTypes.any).isRequired,
};
