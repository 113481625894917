import React from 'react';
import {
  Button, Row, Col, ButtonGroup, ButtonToolbar,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import './index.css';

export default function NavButtons({
  currentStep, onNext, onBack, totalStep,
}) {
  return (
    <Row className={`nav-buttons ${currentStep === 6 ? 'background-color-white' : ''}${currentStep === 1 ? ' invisible' : ''}`}>
      <Col xs={12}>
        <div className="d-flex flex-column">
          <ButtonToolbar size="sm">
            <ButtonGroup className="mr-2 bnp-btn-group">
              <Button variant="bnp" disabled={currentStep <= 1} onClick={() => onBack()}>Précédent</Button>
            </ButtonGroup>
            {onNext && (
            <ButtonGroup className="mr-0 bnp-btn-group">
              <Button variant="bnp" disabled={currentStep >= totalStep} onClick={() => onNext()}>Suivant</Button>
            </ButtonGroup>
            )}
          </ButtonToolbar>
        </div>
      </Col>
    </Row>
  );
}

NavButtons.propTypes = {
  totalStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  onNext: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  onBack: PropTypes.func.isRequired,
};
NavButtons.defaultProps = {
  onNext: false,
};
