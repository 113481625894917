import React from 'react';
import {
  ListGroup, Card, Row, Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useCompletudeChoices } from '../../services/completudeChoicesService';


export default function OmegaTicketComponent({ commitment, warranties }) {
  const formatCellText = (stringToFormat) => {
    if (stringToFormat === undefined) {
      return;
    }
    return stringToFormat.split("\n").map(function(item, idx) {
      return (<>{item}<br/></>)
    });
  }
  const completudeChoices = useCompletudeChoices();
  return (
    <div>
      <Card className="shadow">
        <Card.Body>
          <Card.Title>
            <div>
              <span className="text-success font-weight-bold">Informations importantes de complétude du ticket Omega</span>
            </div>
          </Card.Title>
          <Card className="border-0">
            <Card.Body className="px-0">
              <Card.Title className="text-success">Pour l&apos;engagement</Card.Title>
              <ListGroup>
                {completudeChoices.getAllCommitmentOmegaTickets(commitment.code).map((just) => (
                  <ListGroup.Item key={just.fieldName} className="border-0 text-left">
                    <div className="bg-dark d-inline-block rounded-circle mb-1 mr-1" style={{ width: '5px', height: '5px' }} />
                    {just.fieldName} {' '} {just.value}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>

          <Card className="border-0">
            <Card.Body className="px-0">
              <Card.Title className="text-success">Pour les garanties</Card.Title>
              <ListGroup>
                {warranties.map((w) => (
                  completudeChoices.getAllWarrantiesOmegaTickets(completudeChoices.getWarrantyCode(w)).map((just) => (
                    <ListGroup.Item key={just.fieldName} className="border-0 text-left">
                      <div className="bg-dark d-inline-block rounded-circle mb-1 mr-1" style={{ width: '5px', height: '5px' }} />
                      {just.fieldName} {' '} {just.value}
                    </ListGroup.Item>
                  ))
                ))}
                {!completudeChoices.isOneOrMoreWarranties(warranties) && <div>Pas de garantie</div>}
              </ListGroup>
            </Card.Body>
          </Card>
          <div className="border">
            <Card.Link className={`float-left mt-3 text-dark ${completudeChoices.getCommitmentOmegaCompleteness(commitment.code) !== undefined ? '' : 'disabled-link'}`} target="_blank" href={completudeChoices.getCommitmentOmegaCompleteness(commitment.code)}>Voir la complétude du ticket Omega ></Card.Link>
          </div>
        </Card.Body>
      </Card>
      <Row className="m-0 mt-3 bg-white shadow p-3">
        <Col xs={12}>
          <h5 className="text-success font-weight-bold">Documents pour la mise en oeuvre</h5>
        </Col>
        <Row>
          <div className="px-5 mt-4 text-left">
            Si passage en Agence Crédit, copie de l'écran de synthèse ILC (dernier écran du parcours) <br/>
            {formatCellText(completudeChoices.getJustifyingImplementation(commitment.code))}
          </div>
        </Row>
      </Row>
    </div>

  );
}
OmegaTicketComponent.propTypes = {
  commitment: PropTypes.objectOf(PropTypes.any).isRequired,
  warranties: PropTypes.arrayOf(PropTypes.any).isRequired,
};
